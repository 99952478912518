.product{
    display:grid;
    grid-template-columns: 1;
    /* grid-template-rows: repeat(4, auto); */
    grid-template-rows: 1fr 8rem 12rem 4rem;
    grid-template-areas:
        "image"
        "main"
        "content"
        "action";
    height:100%;
    box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.5);
    border-radius: .3rem;
    margin-bottom:2.5vh;
  }
  
  .product_image{
    grid-area: image;
    width:100%;
    height:auto;
    border-radius: .3rem .3rem 0 0;
  }
  
  .product_main{
    grid-area: main;
    margin: 0 1rem 1rem 1rem;
  }
  
    .product_title{
        margin: 0 1rem 1rem 0;
        font-weight:700;
    }
  
    .product_details{
        display: inline-flex;
    }
  
        .product_detail{
            margin: 0 .4rem;
            padding: .1rem .5rem;
            border-radius: .8rem;
            background:rgba(255,255,255,0.3);
        }
  
        .product_detail:first-child{
            margin-left:0;
        }
  
        .product_detail:last-child{
            margin-right:0;
        }
  
  .product_contents{
    grid-area: content;
    font-size:.85rem;
    margin: 0 1.5rem;
  }
  
  .product_action{
    grid-area: action;
    margin: 0 1.5rem 2rem 0;
    display: inline-flex;
    justify-content: right;
    align-items: center;
  }
  
    .product_price{
        font-size:1.3rem;
        font-weight: 500;
        margin-right:1.5rem;
    }
  
    .product_btn{
        background: #FF003C;
        padding: .89rem;
        border-radius: .5rem;
        overflow: hidden;
        width: 9.15rem;
        height: 2.9rem;
        border:none;
        color:white;
        font-size: .9rem;
        font-weight: 600;
        text-align:left;
        line-height: 1.2rem;
        cursor: pointer;
    }
  
    .product_cart {
        float:left;
        width:1.2rem;
        margin: -.1rem .4rem 0 .1rem;
      }
      
      .product_cartAdd{
          position: absolute;
          left: -4rem;
          width: 1.2rem;
          height: auto;
          margin: -.1rem .4rem 0 0;
          visibility: collapse;
      }
      
      .product_btn:hover {
        transition: .2s ease-out;
        position:relative;
      }
      
      .product_btn:hover .product_cart {
        transform: translate(4rem);
        opacity: 0;
        transition: .35s;
      }
      
      .product_btn:hover .product_cartAdd{
          transition: .25s .1s;
          left: 1rem;
          visibility: visible;
      }
  
    /* ----------------- */
    /* BUTTON TOGGLES */
    /* ----------------- */
    .displayNone{
        display:none;
    }
    
    .productToggle{
        background: #FF003C;
        padding: .89rem;
        border-radius: .5rem;
        overflow: hidden;
        width: 8.5rem;
        height: 2.9rem;
        border:none;
        color:white;
        font-size: .9rem;
        font-weight: 600;
        text-align:left;
        line-height: 1.2rem;
        cursor: pointer;
        transition: 0.1s;
    }
      
    .product_cartAdded{
        float:left;
        width:1.4rem;
        height:auto;
        margin: 0 .5rem 0 .9rem;
    }