.hero_list_container{
    width:95vw;
}

.hero_list{
    list-style-type: none;
    padding: 0;
}

.hero_list__item:nth-of-type(2){
    display:none;
}

.hero_list__item:nth-of-type(3){
    display:none;
}

.hero_list__item:nth-of-type(4){
    display:none;
}

.hero_list__item:nth-of-type(5){
    display:none;
}

.hero_list__item:nth-of-type(6){
    display:none;
}
