@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

*{
  font-family: 'Poppins';
}

.App {
  display:flex;
  justify-content:center;
  cursor:default;
}

.container{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "hero"
    "products";
  width: 95vw;
  height:100%;
  margin: 1rem 0;
  padding:0;
}


/* ----------------- */
/* HERO */
/* ----------------- */
.hero{
  grid-area:hero;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-template-areas:
      ". cart"
      ". ."
      "heroMain ."
      "heroContents ."
      "heroAction ."
      ". ."
      ". ."
      ". .";
  justify-content:left;
  padding: 0 0 0 10rem;
  height:60rem;
  background: url(./components/img/cyberpunk.jpg) no-repeat;
  background-size:cover;
  background-position-y:15%;
  border-radius: .3rem;
  box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.5);
}

.hero_main{
  grid-area: heroMain;
}

  .hero_title{
    font-size:4rem;
    font-weight:700;
    margin-bottom: -.1rem;
  }

  .hero_details{
    display: inline-flex;
    line-height:1rem;
  }

  .hero_detail{
    margin: 1rem;
  }

  .hero_detail:first-child{
    margin-left: .5rem;
  }

.hero_contents{
  grid-area: heroContents;
  width:39%;
  font-size: 1rem;
  margin: 2rem 0;
}

.hero_action{
  grid-area: heroAction;
  display:inline-flex;
  align-items:center;
}

  .hero_price{
    font-size:1.8rem;
    font-weight: 500;
    margin-right:1.5rem;
  }


/* ----------------- */
/* BUTTON */
/* ----------------- */
.hero_button{
  background: #FF003C;
  padding:.9255rem;
  border-radius:.5rem;
  overflow:hidden;
  width:11.5rem;
  height:3.25rem;
  border:none;
  color:#fff;
  font-size:1.1rem;
  font-weight:600;
  text-align:left;
  cursor: pointer;
  transition: 0.1s;
}

.hero_cart {
  float:left;
  width:1.4rem;
  height:auto;
  margin: 0 .5rem;
}

.hero_cartAdd{
    position: absolute;
    left: -4rem;
    width: 1.4rem;
    height: auto;
    margin: 0 .5rem;
    visibility: collapse;

}

.hero_button:hover {
  transition: .2s ease-out;
  position:relative;
}

.hero_button:hover .hero_cart {
  transform: translate(4rem);
  opacity: 0;
  transition: .35s;
}

.hero_button:hover .hero_cartAdd{
    transition: .25s .1s;
    left: .9rem;
    visibility: visible;
}

/* ----------------- */
/* BUTTON TOGGLES */
/* ----------------- */
  .displayNone{
    display:none;
  }

  .heroToggle{
    background: #FF003C;
    padding:.9255rem;
    border-radius:.5rem;
    overflow:hidden;
    width:10.65rem;
    height:3.25rem;
    border:none;
    color:#fff;
    font-size:1.1rem;
    font-weight:600;
    text-align:left;
    cursor: pointer;
    transition: 0.1s;
  }

  .hero_cartAdded{
    float:left;
    width:1.4rem;
    margin: 0 .5rem 0 .9rem;
  }


/* ----------------- */
/* CART */
/* ----------------- */
.cart_container{
  grid-area: cart;
  padding:1rem;
}

.dropCart {
  position: relative;
  display: inline-block;
  justify-self: right;
}

.dropBtn {
  background:rgba(75, 75, 75, 0.1);
  border: none;
  border-radius:.5rem .5rem 0 0;
  cursor:pointer;
}

.checkout{
  width:3rem;
  padding: .8rem .8rem .5rem .8rem;
}

.dropCart-content {
  visibility: collapse;
  position: absolute;
  right:-15%;
  background-color: #f1f1f1;
  min-width: 23rem;
  padding: .5rem 1rem 1rem 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius:.3rem;
  transform: translateY(2rem);
  opacity:0;
}

.dropCart-content a:hover {
  background-color: #f1f1f1;
}

.dropCart:hover .dropCart-content {
  visibility: visible;
  transform: translateY(-.55rem);
  transition: .3s ease-in-out;
  opacity:1;
}

.dropCart:hover .dropBtn {
  background-color: rgba(255, 0, 80, .75);
  transition: .3s;
}

.cart__body{
  width:100%;
}

.cart__total{
  font-size:1.2rem;
  font-weight:600;
  text-align:center;
}

.cart_alert{
  text-align:center;
  font-size:1.2rem;
  font-weight:700;
  color: #FF003C;
  padding:1rem;
}

/* ----------------- */
/* CART ITEMS */
/* ----------------- */
.btn__remove{
  background: #FF003C;
  border:none;
  border-radius: .3rem;
  cursor: pointer;
}

.cart-item{
  padding: 1rem 0;
  width:100%;
}

.cart-item__name{
  font-size:1rem;
  font-weight:500;
  padding-left:.5rem;
}

.cart-item__price{
  position:absolute;
  right:0;
  padding-right: 1rem;
}