.product_list__container{
    margin-top: -.8rem;
}

.product_list{
    display:grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1rem;
    padding:0;
    list-style-type: none;
}

.product_list__item{
    border-radius: .3rem;
}

.product_list__item:nth-of-type(1){
    display:none;
}

.product_list__item:nth-of-type(2){
    background-image: linear-gradient(0deg, #58775b 0%, #3f523d 74%);
}

.product_list__item:nth-of-type(3){
    background-image: linear-gradient(0deg, #ec9f05 0%, #ff4e00 74%);
}

.product_list__item:nth-of-type(4){
    background-image: linear-gradient(0deg, #537895 0%, #0c2b55 70%);
}

.product_list__item:nth-of-type(5){
    background-image: linear-gradient(0deg, #828569 0%, #a7a860 74%);
}

.product_list__item:nth-of-type(6){
    background-image: linear-gradient(0deg, #d83c3c 0%, #640718 74%);
}