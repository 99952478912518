@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero_list_container{
    width:95vw;
}

.hero_list{
    list-style-type: none;
    padding: 0;
}

.hero_list__item:nth-of-type(2){
    display:none;
}

.hero_list__item:nth-of-type(3){
    display:none;
}

.hero_list__item:nth-of-type(4){
    display:none;
}

.hero_list__item:nth-of-type(5){
    display:none;
}

.hero_list__item:nth-of-type(6){
    display:none;
}

.product{
    display:grid;
    grid-template-columns: 1;
    /* grid-template-rows: repeat(4, auto); */
    grid-template-rows: 1fr 8rem 12rem 4rem;
    grid-template-areas:
        "image"
        "main"
        "content"
        "action";
    height:100%;
    box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.5);
    border-radius: .3rem;
    margin-bottom:2.5vh;
  }
  
  .product_image{
    grid-area: image;
    width:100%;
    height:auto;
    border-radius: .3rem .3rem 0 0;
  }
  
  .product_main{
    grid-area: main;
    margin: 0 1rem 1rem 1rem;
  }
  
    .product_title{
        margin: 0 1rem 1rem 0;
        font-weight:700;
    }
  
    .product_details{
        display: inline-flex;
    }
  
        .product_detail{
            margin: 0 .4rem;
            padding: .1rem .5rem;
            border-radius: .8rem;
            background:rgba(255,255,255,0.3);
        }
  
        .product_detail:first-child{
            margin-left:0;
        }
  
        .product_detail:last-child{
            margin-right:0;
        }
  
  .product_contents{
    grid-area: content;
    font-size:.85rem;
    margin: 0 1.5rem;
  }
  
  .product_action{
    grid-area: action;
    margin: 0 1.5rem 2rem 0;
    display: inline-flex;
    justify-content: right;
    align-items: center;
  }
  
    .product_price{
        font-size:1.3rem;
        font-weight: 500;
        margin-right:1.5rem;
    }
  
    .product_btn{
        background: #FF003C;
        padding: .89rem;
        border-radius: .5rem;
        overflow: hidden;
        width: 9.15rem;
        height: 2.9rem;
        border:none;
        color:white;
        font-size: .9rem;
        font-weight: 600;
        text-align:left;
        line-height: 1.2rem;
        cursor: pointer;
    }
  
    .product_cart {
        float:left;
        width:1.2rem;
        margin: -.1rem .4rem 0 .1rem;
      }
      
      .product_cartAdd{
          position: absolute;
          left: -4rem;
          width: 1.2rem;
          height: auto;
          margin: -.1rem .4rem 0 0;
          visibility: collapse;
      }
      
      .product_btn:hover {
        -webkit-transition: .2s ease-out;
        transition: .2s ease-out;
        position:relative;
      }
      
      .product_btn:hover .product_cart {
        -webkit-transform: translate(4rem);
                transform: translate(4rem);
        opacity: 0;
        -webkit-transition: .35s;
        transition: .35s;
      }
      
      .product_btn:hover .product_cartAdd{
          -webkit-transition: .25s .1s;
          transition: .25s .1s;
          left: 1rem;
          visibility: visible;
      }
  
    /* ----------------- */
    /* BUTTON TOGGLES */
    /* ----------------- */
    .displayNone{
        display:none;
    }
    
    .productToggle{
        background: #FF003C;
        padding: .89rem;
        border-radius: .5rem;
        overflow: hidden;
        width: 8.5rem;
        height: 2.9rem;
        border:none;
        color:white;
        font-size: .9rem;
        font-weight: 600;
        text-align:left;
        line-height: 1.2rem;
        cursor: pointer;
        -webkit-transition: 0.1s;
        transition: 0.1s;
    }
      
    .product_cartAdded{
        float:left;
        width:1.4rem;
        height:auto;
        margin: 0 .5rem 0 .9rem;
    }
.product_list__container{
    margin-top: -.8rem;
}

.product_list{
    display:grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1rem;
    padding:0;
    list-style-type: none;
}

.product_list__item{
    border-radius: .3rem;
}

.product_list__item:nth-of-type(1){
    display:none;
}

.product_list__item:nth-of-type(2){
    background-image: -webkit-gradient(linear, left bottom, left top, from(#58775b), color-stop(74%, #3f523d));
    background-image: linear-gradient(0deg, #58775b 0%, #3f523d 74%);
}

.product_list__item:nth-of-type(3){
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ec9f05), color-stop(74%, #ff4e00));
    background-image: linear-gradient(0deg, #ec9f05 0%, #ff4e00 74%);
}

.product_list__item:nth-of-type(4){
    background-image: -webkit-gradient(linear, left bottom, left top, from(#537895), color-stop(70%, #0c2b55));
    background-image: linear-gradient(0deg, #537895 0%, #0c2b55 70%);
}

.product_list__item:nth-of-type(5){
    background-image: -webkit-gradient(linear, left bottom, left top, from(#828569), color-stop(74%, #a7a860));
    background-image: linear-gradient(0deg, #828569 0%, #a7a860 74%);
}

.product_list__item:nth-of-type(6){
    background-image: -webkit-gradient(linear, left bottom, left top, from(#d83c3c), color-stop(74%, #640718));
    background-image: linear-gradient(0deg, #d83c3c 0%, #640718 74%);
}
*{
  font-family: 'Poppins';
}

.App {
  display:flex;
  justify-content:center;
  cursor:default;
}

.container{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "hero"
    "products";
  width: 95vw;
  height:100%;
  margin: 1rem 0;
  padding:0;
}


/* ----------------- */
/* HERO */
/* ----------------- */
.hero{
  grid-area:hero;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-template-areas:
      ". cart"
      ". ."
      "heroMain ."
      "heroContents ."
      "heroAction ."
      ". ."
      ". ."
      ". .";
  justify-content:left;
  padding: 0 0 0 10rem;
  height:60rem;
  background: url(../media/cyberpunk.994f6bc4.jpg) no-repeat;
  background-size:cover;
  background-position-y:15%;
  border-radius: .3rem;
  box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.5);
}

.hero_main{
  grid-area: heroMain;
}

  .hero_title{
    font-size:4rem;
    font-weight:700;
    margin-bottom: -.1rem;
  }

  .hero_details{
    display: inline-flex;
    line-height:1rem;
  }

  .hero_detail{
    margin: 1rem;
  }

  .hero_detail:first-child{
    margin-left: .5rem;
  }

.hero_contents{
  grid-area: heroContents;
  width:39%;
  font-size: 1rem;
  margin: 2rem 0;
}

.hero_action{
  grid-area: heroAction;
  display:inline-flex;
  align-items:center;
}

  .hero_price{
    font-size:1.8rem;
    font-weight: 500;
    margin-right:1.5rem;
  }


/* ----------------- */
/* BUTTON */
/* ----------------- */
.hero_button{
  background: #FF003C;
  padding:.9255rem;
  border-radius:.5rem;
  overflow:hidden;
  width:11.5rem;
  height:3.25rem;
  border:none;
  color:#fff;
  font-size:1.1rem;
  font-weight:600;
  text-align:left;
  cursor: pointer;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.hero_cart {
  float:left;
  width:1.4rem;
  height:auto;
  margin: 0 .5rem;
}

.hero_cartAdd{
    position: absolute;
    left: -4rem;
    width: 1.4rem;
    height: auto;
    margin: 0 .5rem;
    visibility: collapse;

}

.hero_button:hover {
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  position:relative;
}

.hero_button:hover .hero_cart {
  -webkit-transform: translate(4rem);
          transform: translate(4rem);
  opacity: 0;
  -webkit-transition: .35s;
  transition: .35s;
}

.hero_button:hover .hero_cartAdd{
    -webkit-transition: .25s .1s;
    transition: .25s .1s;
    left: .9rem;
    visibility: visible;
}

/* ----------------- */
/* BUTTON TOGGLES */
/* ----------------- */
  .displayNone{
    display:none;
  }

  .heroToggle{
    background: #FF003C;
    padding:.9255rem;
    border-radius:.5rem;
    overflow:hidden;
    width:10.65rem;
    height:3.25rem;
    border:none;
    color:#fff;
    font-size:1.1rem;
    font-weight:600;
    text-align:left;
    cursor: pointer;
    -webkit-transition: 0.1s;
    transition: 0.1s;
  }

  .hero_cartAdded{
    float:left;
    width:1.4rem;
    margin: 0 .5rem 0 .9rem;
  }


/* ----------------- */
/* CART */
/* ----------------- */
.cart_container{
  grid-area: cart;
  padding:1rem;
}

.dropCart {
  position: relative;
  display: inline-block;
  justify-self: right;
}

.dropBtn {
  background:rgba(75, 75, 75, 0.1);
  border: none;
  border-radius:.5rem .5rem 0 0;
  cursor:pointer;
}

.checkout{
  width:3rem;
  padding: .8rem .8rem .5rem .8rem;
}

.dropCart-content {
  visibility: collapse;
  position: absolute;
  right:-15%;
  background-color: #f1f1f1;
  min-width: 23rem;
  padding: .5rem 1rem 1rem 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius:.3rem;
  -webkit-transform: translateY(2rem);
          transform: translateY(2rem);
  opacity:0;
}

.dropCart-content a:hover {
  background-color: #f1f1f1;
}

.dropCart:hover .dropCart-content {
  visibility: visible;
  -webkit-transform: translateY(-.55rem);
          transform: translateY(-.55rem);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  opacity:1;
}

.dropCart:hover .dropBtn {
  background-color: rgba(255, 0, 80, .75);
  -webkit-transition: .3s;
  transition: .3s;
}

.cart__body{
  width:100%;
}

.cart__total{
  font-size:1.2rem;
  font-weight:600;
  text-align:center;
}

.cart_alert{
  text-align:center;
  font-size:1.2rem;
  font-weight:700;
  color: #FF003C;
  padding:1rem;
}

/* ----------------- */
/* CART ITEMS */
/* ----------------- */
.btn__remove{
  background: #FF003C;
  border:none;
  border-radius: .3rem;
  cursor: pointer;
}

.cart-item{
  padding: 1rem 0;
  width:100%;
}

.cart-item__name{
  font-size:1rem;
  font-weight:500;
  padding-left:.5rem;
}

.cart-item__price{
  position:absolute;
  right:0;
  padding-right: 1rem;
}
